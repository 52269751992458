* {
    font-family: 'Ubuntu', sans-serif;
}

/* general component css - use these to maintain a fluid web experience */

body {
    background-color: rgb(255, 251, 194);
}

h1 {
    font-size: 40px;
}
h2 {
    font-size: 30px;
}

button {
    /* looks */
    background-color: white;
    border-radius: 15pt;
    
    /* spacing and sizing*/
    padding:10pt;
    margin: 10pt;
    font-size: 20px;
}

button:hover {
    background-color: rgb(236, 183, 236);
}

input {
    /* looks */
    border-radius: 15pt;

    /* spacing and sizing */
    padding: 10pt;
    margin: 10pt;
    font-size: 20px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

select {
    /* looks */
    background-color: white;
    font-size: 20px;
    /* spacing and sizing */
    padding: 10pt;
    margin: 10pt;
    font-size: 20px;

}


/* accountSelection.js  */
.accountSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* form.js */
.logForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* landing.js */
.landingTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerLanding {
    position: fixed;
    bottom: 50px;
    display: flex;
    flex-direction: column;
}

/* addevent.js */
.creditSelect {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* finished.js */
.finished {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* fetchResults.js */
.results {
    display: flex;
    flex-direction: column;
}
.eventContainer {
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:5px;

}
.eventBox {
    background-color: rgb(236, 183, 236);
    border-radius: 15pt;
    padding:10pt;
    margin: 10pt;
}

@media only screen and (max-width: 768px) {
    .eventContainer {
        display: flex;
        flex-direction: column;
    }
    
}